import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <div className='ui raised very padded text container segment' style={{marginTop:'80px'}}>
            <h3 className='ui header'>about</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti dolores voluptate rerum quaerat velit ipsa voluptas aut harum, tempora ab nisi perferendis quisquam repudiandae, vel beatae, eum laborum molestias similique.</p>
            </div>
        )
    }
}
